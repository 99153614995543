<template>
  <div class="divs">
    <div class="nav divs">
        <indexNav ref="navs"/>
    </div>
    <div class="divs">
      <news />
    </div>
    <div class="about">
      <indexAbout />
    </div>
  </div>
</template>

<script>
import indexNav from "../navs/indexNav.vue";
import news from "../index/module/news.vue";
import indexAbout from "../indexBtn/indexAbout.vue";

export default {
  data() {
    return {
    };
  },
  methods: {
    toPath(path){
      this.$router.push('/'+path)
    },
  },
  components: {
    indexNav,
    news,
    indexAbout,
  },
};
</script>

<style scoped>
.nav {
  width: 100%;
}
.divs {
  width: 99vw;
  padding: 0px;
  margin: 0 auto;
}
.productList {
  height: 50vw;
  margin-top: 40px;
  background: #000;
}
.about {
  background: #000;
  min-height: 200px;
  margin-top: 40px;
}
</style>
